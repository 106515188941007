import * as React from 'react';
import { Helmet } from 'react-helmet';
import { BlockContent, Blocks, PostList } from '~/components';

export default function Page({ pageContext, data }) {
  const { primaryColor, secondaryColor, bgColor, blocks } = pageContext;

  // set page theme
  let styles = [];
  primaryColor && styles.push(`--primary-color: ${primaryColor}`);
  secondaryColor && styles.push(`--secondary-color: ${secondaryColor}`);
  bgColor && styles.push(`--background-color: ${bgColor}`);
  styles = styles.join('; ');

  return (
    <>
      <Helmet>
        <style>{`:root {${styles}}`}</style>
      </Helmet>
      <BlockContent>
        <Blocks blocks={blocks} />
        <PostList posts={data.posts.nodes} />
      </BlockContent>
    </>
  );
}

export const query = graphql`
  query blog {
    posts: allWpPost {
      nodes {
        id: databaseId
        title
        uri
        excerpt
        primaryColor
        featuredImage {
          remoteFile {
            ...BlogImage
          }
          altText
        }
      }
    }
  }
`;
